import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FilledInput,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTableItem from "react-data-table-component";
import localizationStrings from "../../utils/localizations";
import { baseUrl } from "../../utils/constants";
import { useLocation, useParams } from "react-router-dom";
import RichTextEditor from "react-rte";

export default function SurahDetails() {
  const [data, setData] = useState({ details: [] });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentSelected, setSelected] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const location = useLocation();
  const [type, setType] = useState(0);
  const params = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [intro, setIntro] = useState(RichTextEditor.createEmptyValue());

  const handleOpen = (item, indx) => {
    setSelected(item);
    setSelectedIndex(indx);
    setTitle(item.title);
    setText(item.text.join("\n\n"));
    setModalVisible(true);
  };
  const handleClose = () => {
    setSelected(null);
    setSelectedIndex(null);
    setTitle("");
    setText("");
    setModalVisible(false);
  };

  const getSurahList = async (silent = false) => {
    if (!silent) setLoading(true);
    try {
      const data = await axios.get(baseUrl + "quran/" + params.number + "?type=" + type);
      if (data.data.success) {
        console.log(data.data);
        if (type == 0) {
          setIntro(RichTextEditor.createValueFromString(data.data.data.intro ?? "", "html"));
        } else {
          setData(data.data.data);
          setFilteredData(data.data.data.details);
        }
      }
      if (!silent) setLoading(false);
    } catch (e) {
      if (!silent) setLoading(false);
      console.warn(e);
    }
  };
  useEffect(() => {
    getSurahList();
    if (type == 1) {
      const int = setInterval(() => {
        getSurahList(true);
      }, 60 * 1000);
      return () => clearInterval(int);
    }
  }, [type]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tp = queryParams.get("type");
    if (tp && tp !== type) {
      setType(tp);
    } else if (!tp) {
      window.location.href = window.location.href + "?type=0";
    }
  }, [location, type]);

  const pressSave = async () => {
    if (!title || !text) {
      alert("Please fill all fields");
      return;
    }
    setModalLoading(true);
    try {
      data.details = data.details || [];
      if (currentSelected) {
        data.details[selectedIndex] = { ...currentSelected, title, text: text.split("\n\n") };
      } else {
        data.details.push({ title, text: text.split("\n\n") });
      }
      // if (currentSelected) body["id"] = currentSelected.id;
      await axios.put(baseUrl + "quran/" + params.number + "/details?type=" + type, { details: data.details });
      await getSurahList(true);
      setModalLoading(false);
      handleClose();
    } catch (e) {
      setModalLoading(false);
      console.warn(e);
    }
  };

  const onSave = async () => {
    setButtonLoading(true);
    try {
      console.log(intro.toString("html"));
      const data = await axios.post(baseUrl + "quran/" + params.number + "/details?type=" + type, { intro: intro.toString("html") });

      if (data.data.success) {
        getSurahList(true);
        // alert("Saved Successfully");
      } else {
        alert(data.data?.message || "Something went wrong!");
      }
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
      alert(e?.response?.data?.message || e?.message || "Something went wrong");
      console.warn(e);
    }
  };

  if (loading) {
    return (
      <div className="tracking-container" style={{ alignItems: "center", justifyContent: "center" }}>
        <div className="loader"></div>
      </div>
    );
  }

  const handleSearch = () => {
    setFilteredData(data.details.filter((v) => JSON.stringify(v).toLocaleLowerCase().includes(search.toLocaleLowerCase())));
  };

  if (type == 0) {
    return (
      <div style={{ height: "calc(100vh - 56px)", width: "100%", padding: 30 }}>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div style={{ height: 20 }} />
            <RichTextEditor
              value={intro}
              onChange={setIntro}
              editorStyle={{ height: 400 }}
              toolbarConfig={{
                // Optionally specify the groups to display (displayed in the order listed).
                display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
                INLINE_STYLE_BUTTONS: [
                  { label: "Bold", style: "BOLD" },
                  { label: "Italic", style: "ITALIC" },
                  { label: "Strikethrough", style: "STRIKETHROUGH" },
                  { label: "Monospace", style: "CODE" },
                  { label: "Underline", style: "UNDERLINE" },
                ],
                BLOCK_TYPE_DROPDOWN: [
                  { label: "Normal", style: "unstyled" },
                  { label: "Heading Large", style: "header-one" },
                  { label: "Heading Medium", style: "header-two" },
                  { label: "Heading Small", style: "header-three" },
                  { label: "Code Block", style: "code-block" },
                ],
                BLOCK_TYPE_BUTTONS: [
                  { label: "UL", style: "unordered-list-item" },
                  { label: "OL", style: "ordered-list-item" },
                  { label: "Blockquote", style: "blockquote" },
                ],
              }}
            />

            <div style={{ height: 20 }} />

            {buttonLoading ? (
              <div className="loader"></div>
            ) : (
              <Button onClick={() => onSave()} variant="contained">
                {localizationStrings.Save}
              </Button>
            )}
          </Box>
        )}
      </div>
    );
  }

  return (
    <div style={{ height: "calc(100vh - 60px)", width: "100%", padding: 30 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", mb: "15px" }}>
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setModalVisible(true)}> */}
          <Button variant="contained" onClick={() => setModalVisible(true)}>
            {localizationStrings.Add}
          </Button>
          {/* </Box> */}
          <div style={{ flex: 1, display: "flex" }} />
          <Input
            style={{ backgroundColor: "#fff" }}
            placeholder={localizationStrings.Search}
            color="primary"
            value={search}
            onChange={(e) => setSearch(e.nativeEvent.target.value)}
            endAdornment={
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <IconButton
                  onClick={() => {
                    setFilteredData(data.details);
                    setSearch("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton type="submit" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </Box>
            }
          />
        </Box>
      </form>
      {data.details.length === 0 ? (
        <div className="tracking-container" style={{ alignItems: "center", justifyContent: "center" }}>
          <h3 style={{ textAlign: "center" }}>{localizationStrings.NoDetails}</h3>
        </div>
      ) : (
        <DataTableItem
          data={filteredData}
          columns={[
            {
              selector: (row) => row.title,
              name: <div>{localizationStrings.Title}</div>,
              sortable: true,
              grow: 1,
              wrap: true,
            },
            {
              selector: (row, i) => (
                <div>
                  <br />
                  {row.text
                    .join("\n\n")
                    .substr(0, Math.max(120, Math.min(row.text.join("\n\n").indexOf("।") + 1, row.text.join("\n\n").indexOf("-") + 1, 300)))
                    .split("\n")
                    .map((v, i, a) => (
                      <div>
                        {v}
                        {i == a.length - 1 && row.text.join("\n\n").length > 70 && (
                          <span style={{ color: "#4A4B6A", cursor: "pointer" }} onClick={() => handleOpen(row, i)}>
                            {"  "} Read More
                          </span>
                        )}
                        <br />
                      </div>
                    ))}
                  <br />
                </div>
              ),
              name: <div>{localizationStrings.Text}</div>,
              sortable: true,
              grow: 3,
              wrap: true,
            },
            {
              selector: (row, i) => (
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }}>
                  {/* <Button variant='outlined' startIcon={<VisibilityIcon />}>
                  View
                </Button>
                <div style={{ width: 10, height: 10 }} /> */}
                  <Button style={{ color: "black" }} variant="outlined" startIcon={<EditIcon />} onClick={() => handleOpen(row, i)}>
                    {localizationStrings.Edit}
                  </Button>
                  <div style={{ width: 10, height: 10 }} />
                  <Button
                    style={{ color: "black" }}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      if (window.confirm(localizationStrings.AreYouSureDelete)) {
                        const details = [...data.details.filter((v, j) => j !== i)];
                        setData({ ...data, details: details });
                        setFilteredData(details);
                        axios.put(baseUrl + "quran/" + params.number + "/details?type=" + type, { details });
                        // getSurahList(true);
                      }
                    }}
                  >
                    {localizationStrings.Delete}
                  </Button>
                </Box>
              ),
              name: <div>{localizationStrings.Options || "Options"}</div>,
              sortable: true,
              grow: 1,
              wrap: true,
            },
          ]}
          highlightOnHover
          fixedHeader
          pagination
          responsive
          style={{ height: "calc(100vh - 82px)" }}
          customStyles={{
            headCells: { style: { backgroundColor: "#4A4B6A", color: "#000", fontSize: "11px" } },
            responsiveWrapper: { style: { height: "calc(100vh - 220px)" } },
            tableWrapper: { style: { width: "max(1000px, 100%)" } },
            cells: { style: { fontSize: "11px" } },
          }}
          paginationComponent={({ rowsPerPage, rowCount, currentPage, onChangePage, onChangeRowsPerPage }) => (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", minHeight: 56 }}>
              <Typography sx={{ margin: "0 24px", color: "rgba(0, 0, 0, 0.54)", fontSize: "13px" }}>
                {(currentPage - 1) * rowsPerPage + 1}-{currentPage * rowsPerPage > rowCount ? rowCount : currentPage * rowsPerPage} of {rowCount}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === 1}
                  style={{ fill: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})`, color: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})` }}
                  onClick={() => onChangePage(1)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                  </svg>
                </button>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === 1}
                  style={{ fill: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})`, color: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})` }}
                  onClick={() => onChangePage(currentPage - 1)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === Math.ceil(rowCount / rowsPerPage)}
                  style={{
                    fill: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                    color: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                  }}
                  onClick={() => onChangePage(currentPage + 1)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === Math.ceil(rowCount / rowsPerPage)}
                  style={{
                    fill: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                    color: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                  }}
                  onClick={() => onChangePage(Math.ceil(rowCount / rowsPerPage))}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                  </svg>
                </button>
              </div>
            </Box>
          )}
        />
      )}
      {modalVisible && (
        <div className="modal-container">
          <div aria-hidden="true" className="modal-back" onClick={handleClose}></div>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: "70%", md: "60%", lg: "40%", xl: "30%" },
              maxHeight: "90%",
              overflowY: "scroll",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{ position: "absolute", top: 20, [localizationStrings.getLanguage() === "ar" ? "left" : "right"]: 20 }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography id="modal-modal-description" sx={{ mt: 5, textAlign: "center", fontWeight: "700", fontSize: 18 }}>
              {currentSelected ? localizationStrings.Edit : localizationStrings.Add}
            </Typography>
            <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <div style={{ height: 20 }} />
              <TextField label={localizationStrings.Title} variant="filled" value={title} onChange={(e) => setTitle(e.target.value)} />
              <div style={{ height: 20 }} />
              <TextField
                label={localizationStrings.Text}
                multiline
                minRows={3}
                variant="filled"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <div style={{ height: 20 }} />
              {modalLoading ? (
                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                  <div className="loader" />
                </div>
              ) : (
                <Button onClick={() => pressSave()} variant="contained">
                  {localizationStrings.Save}
                </Button>
              )}
              <div style={{ height: 20 }} />
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
}
