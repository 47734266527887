import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AdminUsers from "./pages/dashboard/AdminUsers";
import Dashboard from "./pages/Dashboard";
import DashboardHome from "./pages/DashboardHome";
import Home from "./pages/Home";
import Login from "./pages/Login";
import localizationStrings, { firstTrySetLang } from "./utils/localizations";
import PushNotifications from "./pages/PushNotifications";
import Pages from "./pages/dashboard/Pages";
import Shabd from "./pages/dashboard/Shabd";
import Tafheem from "./pages/dashboard/Tafheem";
import Notification from "./pages/dashboard/Notification";
import SurahList from "./pages/dashboard/SurahList";
import AyahList from "./pages/dashboard/AyahList";
import SurahDetails from "./pages/dashboard/SurahDetails";
import PageEditor from "./pages/dashboard/PageEditor";

function App() {
  const getTheme = () =>
    createTheme({
      typography: {
        fontFamily: localizationStrings.getLanguage() == "en" ? "Aldrich" : "",
      },
      palette: {
        primary: {
          main: "#4A4B6A",
        },
      },
      direction: localizationStrings.getLanguage() == "ar" ? "rtl" : "ltr",
    });
  const [theme, setTheme] = useState(getTheme());
  const [first, setFirst] = useState(1);
  const [_, setReload] = useState({});

  const refreshTheme = () => {
    setTheme(getTheme());
  };

  useEffect(() => {
    if (first == 2) {
      const lng = localStorage.getItem("language");
      localizationStrings.setLanguage(lng || "en");
      // localizationStrings.setLanguage('en');
      setFirst(first + 1);
      refreshTheme();
    } else if (first == 1) {
      setFirst(first + 1);
    }
  }, [first]);

  useEffect(() => {
    const storageListener = (e) => {
      const lng = localStorage.getItem("language");
      localizationStrings.setLanguage(lng || "en");

      // localizationStrings.setLanguage('en');
      refreshTheme();
    };
    window.addEventListener("storage", storageListener);
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const checkRoleExists = (roles) => {
    const user_role = (JSON.parse(localStorage.getItem("user") || "{}") || {}).role;
    if (!user_role) return false;
    return roles.indexOf(user_role) > -1;
  };

  return (
    <div
      dir={localizationStrings.getLanguage() == "ar" ? "rtl" : "ltr"}
      style={{ fontFamily: localizationStrings.getLanguage() == "en" ? "Aldrich" : "GESndBook" }}
    >
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="dashboard-home" element={<DashboardHome reload={() => setReload({})} />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            {checkRoleExists(["admin"]) && <Route path="pages" element={<Pages />} />}
            {checkRoleExists(["admin"]) && <Route path="pages/:id" element={<PageEditor />} />}
            {checkRoleExists(["admin"]) && <Route path="shabd" element={<Shabd />} />}
            {checkRoleExists(["admin"]) && <Route path="tafheem/:title" element={<Tafheem />} />}
            {checkRoleExists(["admin"]) && <Route path="push-notifications" element={<PushNotifications />} />}
            {checkRoleExists(["admin"]) && <Route path="admin-users" element={<AdminUsers />} />}
            {checkRoleExists(["admin"]) && <Route path="notification" element={<Notification />} />}
            {checkRoleExists(["admin"]) && <Route path="quran" element={<SurahList />} />}
            {checkRoleExists(["admin"]) && <Route path="quran/details/:number" element={<SurahDetails />} />}
            {checkRoleExists(["admin"]) && <Route path="quran/:number" element={<AyahList />} />}
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
