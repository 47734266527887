import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ImageAnnotator, useImageAnnotator } from "react-image-label";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import localizationStrings from "../../utils/localizations";
import axios from "axios";
import { baseUrl } from "../../utils/constants";

export default function PageEditor() {
  const { setHandles, annotator } = useImageAnnotator();
  const params = useParams();
  const [surah, setSurah] = useState();
  const [ayah, setAyah] = useState();
  const [surahNames, setSurahNames] = useState([]);
  const [originalWidth, setOriginalWidth] = useState();
  const [originalHeight, setOriginalHeight] = useState();
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function createArray(x, y) {
    return Array.from({ length: y - x + 1 }, (_, i) => x + i);
  }

  const getData = async (silent = false) => {
    if (!silent) setLoading(true);
    try {
      const data = await axios.get(baseUrl + "pages/" + params.id);
      if (data.data.success) {
        setData(data.data.data);
        setSurah(data.data.data.start_surah);
        setAyah(data.data.data.start_ayah);
        setSurahNames(data.data.quran_surah_names);
        const nboundingBoxes = [];
        for (let item of data.data.data.bounding_boxes) {
          for (let box of item.boxes) {
            nboundingBoxes.push({
              surah: item.surah_no,
              ayah: item.ayahs_no,
              xmin: box.xmin,
              xmax: box.xmax,
              ymin: box.ymin,
              ymax: box.ymax,
              color: seededColor(item.ayahs_no),
            });
          }
        }
        setBoundingBoxes(nboundingBoxes);
        if (nboundingBoxes.length > 0) {
          setOriginalHeight(data.data.data.image_height);
          setOriginalWidth(data.data.data.image_width);
        }
      }
      if (!silent) setLoading(false);
    } catch (e) {
      if (!silent) setLoading(false);
      console.warn(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function isColorDark(hex) {
    // Ensure the hex color is valid
    if (!/^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(hex)) {
      throw new Error("Invalid HEX color.");
    }

    // Expand shorthand hex (#FFF to #FFFFFF)
    if (hex.length === 4) {
      hex = "#" + [...hex.slice(1)].map((c) => c + c).join("");
    }

    // Extract RGB components
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    // Calculate relative luminance
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // A threshold of 0.5 is often used to classify colors as light or dark
    return luminance < 0.5; // Returns true if the color is dark
  }

  function seededColor(seed) {
    // Predefined array of colors
    const colors = ["#FF0000", "#0000FF", "#00FF00", "#FF00FF", "#FFFF00"];

    // Use the seed to pick a color
    const index = seed % colors.length;

    return colors[index];
  }

  const save = () => {
    let height = originalHeight;
    let width = originalWidth;
    if (!originalHeight || !originalWidth) {
      setOriginalHeight(annotator.container.clientHeight);
      setOriginalWidth(annotator.container.clientWidth);
      height = annotator.container.clientHeight;
      width = annotator.container.clientWidth;
    }
    const data = {
      surah,
      ayah,
      image: annotator.getShapes().map((x) => ({
        xmin: Math.min(x.points[0][0], x.points[1][0], x.points[2][0], x.points[3][0]) / width,
        xmax: Math.max(x.points[0][0], x.points[1][0], x.points[2][0], x.points[3][0]) / width,
        ymin: Math.min(x.points[0][1], x.points[1][1], x.points[2][1], x.points[3][1]) / height,
        ymax: Math.max(x.points[0][1], x.points[1][1], x.points[2][1], x.points[3][1]) / height,
      })),
    };
    console.log(data);
    // annotator.delete(annotator.getShapes()[0].id);
    const newBoxes = [];
    for (let i = 0; i < data.image.length; i++) {
      newBoxes.push({ ...data.image[i], color: seededColor(data.ayah), surah: data.surah, ayah: data.ayah });
    }
    for (let shape of annotator.getShapes()) {
      annotator.delete(shape.id);
    }
    console.log(newBoxes);
    setBoundingBoxes([...boundingBoxes, ...newBoxes]);
  };

  const completeImage = () => {
    const newBoundingBoxes = [];
    for (let box of boundingBoxes) {
      const old = newBoundingBoxes.find((x) => x.surah_no == box.surah && x.ayahs_no == box.ayah);
      if (old) {
        old.boxes.push({
          xmin: box.xmin,
          xmax: box.xmax,
          ymin: box.ymin,
          ymax: box.ymax,
        });
      } else {
        newBoundingBoxes.push({
          surah_no: box.surah,
          ayahs_no: box.ayah,
          boxes: [
            {
              xmin: box.xmin,
              xmax: box.xmax,
              ymin: box.ymin,
              ymax: box.ymax,
            },
          ],
        });
      }
    }
    try {
      setLoading(true);
      const data = {
        id: params.id,
        image_height: originalHeight,
        image_width: originalWidth,
        bounding_boxes: newBoundingBoxes,
      };
      axios.put(baseUrl + "pages/" + params.id, data);
      navigate("/dashboard-home/pages");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.warn(e);
      alert(e.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!data) return <div>Invalid Page</div>;

  return (
    <div style={{ height: "calc(100vh - 60px)", width: "100%", padding: 30 }}>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div style={{ position: "relative" }}>
          <ImageAnnotator
            // shapes={boundingBoxes}
            setHandles={setHandles}
            naturalSize={true}
            imageUrl={data.page_image}
            // imageUrl={require("./../../assets/dummy/page_1.jpg")}
            onReady={(annotator) => {
              annotator.drawRectangle();
            }}
          />
          {boundingBoxes.map((box) => (
            <>
              <div
                style={{
                  position: "absolute",
                  top: box.ymin * originalHeight,
                  left: box.xmin * originalWidth,
                  width: (box.xmax - box.xmin) * originalWidth,
                  height: (box.ymax - box.ymin) * originalHeight,
                  backgroundColor: box.color + "30",
                  border: "1px solid " + box.color,
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: -5,
                    right: 10,
                    color: isColorDark(box.color) ? "white" : "black",
                    paddingRight: 5,
                    paddingLeft: 5,
                    borderRadius: 4,
                    fontSize: 10,
                    backgroundColor: box.color,
                    display: "flex",
                  }}
                >
                  {box.surah}:{box.ayah} <div style={{ paddingLeft: 5 }}>X</div>
                </div>
              </div>
              <div
                onClick={() => {
                  setBoundingBoxes(boundingBoxes.filter((x) => x !== box));
                }}
                style={{
                  position: "absolute",
                  top: box.ymin * originalHeight - 4,
                  right: originalWidth - (box.xmin * originalWidth + (box.xmax - box.xmin) * originalWidth) + 11,
                  color: "transparent",
                  paddingRight: 5,
                  paddingLeft: 5,
                  borderRadius: 4,
                  fontSize: 10,
                  backgroundColor: "transparent",
                  display: "flex",
                  cursor: "pointer",
                }}
              >
                {box.surah}:{box.ayah} <div style={{ paddingLeft: 5 }}>X</div>
              </div>
            </>
          ))}
        </div>
        <div style={{ flex: 1, paddingRight: "10%" }}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="demo-simple-select-label">Surah</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={surah} onChange={(e) => setSurah(e.target.value)}>
              {/* <MenuItem value={2}>2 - Al-Baqarah</MenuItem> */}
              {createArray(data.start_surah, data.end_surah).map((x) => (
                <MenuItem value={x}>
                  {x} - {surahNames[x - 1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: 20 }} />
          <FormControl fullWidth variant="filled">
            <InputLabel id="demo-simple-select-label">Ayah</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={ayah} onChange={(e) => setAyah(e.target.value)}>
              {/* {new Array(11).fill("").map((_, i) => (
                <MenuItem value={i + 6}>{i + 6}</MenuItem>
              ))} */}
              {createArray(data.start_ayah, data.end_ayah).map((x) => (
                <MenuItem value={x}>{x}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ height: 20 }} />

          <Button onClick={() => save()} variant="contained">
            {localizationStrings.Save}
          </Button>
        </div>
      </div>
      <Button fullWidth onClick={() => completeImage()} variant="contained">
        Complete Image
      </Button>
    </div>
  );
}
