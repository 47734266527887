import React from "react";
import { Link } from "react-router-dom";
import localizationStrings from "../utils/localizations";

export default function Home() {
  return (
    <div className="home-container">
      <div className="home-page-login">
        <Link class="button" to={"/login"}>
          {localizationStrings.AdminLogin}
        </Link>
      </div>
      {/* <img src={require("./../assets/images/logo-tafheem.png")} className="image" alt="Unable to load" /> */}
      <div className="hero-text">
        <h1 style={{ color: "white" }}>Quran App Home Page</h1>
      </div>
      <div class="hero-cta">
        {/* <a class="button" href={require("./../assets/apk/Irshadni_2.11.apk")} download> */}
        {/* <a class="button" download>
          {localizationStrings.DownloadApp}
        </a> */}
      </div>
      {/* <div class='hero-cta' style={{ paddingTop: '10px' }}>
        <a class='button' href={require('./../assets/apk/Irshadni_2.8_beta.apk')} download>
          Download Beta Irshadni Application
        </a>
      </div> */}
    </div>
  );
}
